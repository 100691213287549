/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Layout } from '@leshen/gatsby-theme-contentful'

import {
  Banner,
  Brandy,
  Hero,
  SplitContent,
  VariableContent,
  AvailabilityForm,
  Image,
  List,
  ListItem,
  ModalWithState,
  SVGImage,
  Accordion,
  PackageCard,
  LeshenPhoneCTA,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Stack, Columns, Column, Dropdown, Typography } from '@leshen/ui'
import { graphql, Link as GatsbyLink, navigate } from 'gatsby'

import SeoCtaBar from '../components/SeoCtaBar'
import getFullStateName from '../utils/getFullStateName'

const StateTemplate = ({ data }) => {
  const {
    Meta_Description,
    State,
    Promo_Code,
    Page_Path,
    Meta_Title,
    FAQ_Group,
    State_Specific_Content,
  } = data.pageInfo.edges[0].node

  const { rotatedNumber } = useMapi()

  const stateName = getFullStateName(State.toUpperCase())

  const brandyPackageList = [
    'wds-tier1a-internet1',
    'wds-tier1a-internet2',
    'wds-tier1a-internet3',
    'wds-tier1b-internet5',
  ]

  const packageList = brandyPackageList
    .map((packageId) =>
      data.allContentfulPackage.edges.find(
        ({ node }) => node.identifier === packageId
      )
    )
    .filter((node) => node)

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
    },
  }

  const FaqQuestions = {
    1: [
      {
        title: `What Windstream services are available in ${stateName}?`,
        content: (
          <div>
            <Typography>
              See which services are available in {stateName} by viewing the
              internet and phone package cards at the top of this page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much is it to get Windstream service in ${stateName}?`,
        content: (
          <div>
            <Typography>
              The price of Windstream packages varies based on what package you
              choose. Take a look at the package cards at the top of this page
              for more details.
            </Typography>
          </div>
        ),
      },
      {
        title: `How do I reach Kinetic by Windstream support in ${stateName}?`,
        content: (
          <div>
            <Typography>
              Speak with a Kinetic by Windstream support agent at{' '}
              <a
                href={`
              tel:${rotatedNumber}
              `}
              >
                {rotatedNumber}
              </a>
              .
            </Typography>
          </div>
        ),
      },
      {
        title: `Am I able to get home phone with Windstream internet in ${stateName}?`,
        content: (
          <div>
            <Typography>
              Yes, absolutely. For more information, view the Kinetic by
              Windstream Phone Services section on this page, or view our{' '}
              <a href="/packages">phone and internet packages</a> page.
            </Typography>
          </div>
        ),
      },
    ],
    2: [
      {
        title: `Does ${stateName} have Windstream internet services?`,
        content: (
          <div>
            <Typography>
              Yes, {stateName} has Windstream internet services. For more
              information about the speeds offered in your area, check out the
              package cards at the top of this page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much do internet and home phone packages cost in ${stateName}?`,
        content: (
          <div>
            <Typography>
              Internet and home phone package prices depend on which plans you
              select. Take a look at the offers at the top of this page or visit
              our <a href="/packages">phone and internet packages</a> page for
              more information.
            </Typography>
          </div>
        ),
      },
      {
        title: `How do I contact Windstream in ${stateName}?`,
        content: (
          <div>
            <Typography>
              Contact Windstream customer support by calling{' '}
              <a
                href={`
              tel:${rotatedNumber}
              `}
              >
                {rotatedNumber}
              </a>
              .
            </Typography>
          </div>
        ),
      },
      {
        title: `What fiber internet bundles does Windstream offer?`,
        content: (
          <div>
            <Typography>
              Windstream offers several fiber internet packages, with speeds up
              to 1 Gig. Check out our{' '}
              <a href="/kinetic-internet/gig">Gig internet</a> page for more
              details.
            </Typography>
          </div>
        ),
      },
    ],
    3: [
      {
        title: `Is there fiber internet available in ${stateName}?`,
        content: (
          <div>
            <Typography>
              Yes, Windstream Fiber is available in {stateName}.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much is it to get Windstream service in ${stateName}?`,
        content: (
          <div>
            <Typography>
              Windstream service in {stateName} varies in cost, depending on
              what you order. Check out the package cards at the top of this
              page for more information.
            </Typography>
          </div>
        ),
      },
      {
        title: `What is the contact number for Windstream in ${stateName}?`,
        content: (
          <div>
            <Typography>
              The customer service number for Windstream in {stateName} is
              <a
                href={`
              tel:${rotatedNumber}
              `}
              >
                {rotatedNumber}
              </a>
              .
            </Typography>
          </div>
        ),
      },
      {
        title: `Does Windstream offer bundles in ${stateName}?`,
        content: (
          <div>
            <Typography>
              Yes, Windstream offers phone and internet bundles in {stateName}.
            </Typography>
          </div>
        ),
      },
    ],
    4: [
      {
        title: `Am I able to get Windstream Fiber Internet in ${stateName}`,
        content: (
          <div>
            <Typography>
              Yes, you can get Windstream Fiber in {stateName}.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much does Windstream Internet cost in ${stateName}?`,
        content: (
          <div>
            <Typography>
              The price of Windstream Internet depends on which service you
              order. Check out the package cards at the top of this page for
              more information.
            </Typography>
          </div>
        ),
      },
      {
        title: `What is the number for Windstream in ${stateName} to add a service?`,
        content: (
          <div>
            <Typography>
              The number to add a service in {stateName} is{' '}
              <a
                href={`
              tel:${rotatedNumber}
              `}
              >
                {rotatedNumber}
              </a>
              .
            </Typography>
          </div>
        ),
      },
      {
        title: `Does Windstream in ${stateName} offer bundles with internet?`,
        content: (
          <div>
            <Typography>
              Yes, you can bundle Windstream phone and internet in {stateName}.
              Check out our <a href="/packages">phone and internet packages</a>{' '}
              page for more details.
            </Typography>
          </div>
        ),
      },
    ],
    5: [
      {
        title: `Does Windstream offer internet services in ${stateName}?`,
        content: (
          <div>
            <Typography>
              Yes, Windstream offers high-speed internet services across
              {stateName}. Check out which plans are available in your area by
              visiting our <a href="/availability">availability page</a>.
            </Typography>
          </div>
        ),
      },
      {
        title: `What other services can I get with Windstream in ${stateName}?`,
        content: (
          <div>
            <Typography>
              Windstream offers home phone and DISH TV services in {stateName}{' '}
              in addition to high-speed Kinetic internet. Check out our{' '}
              <a href="/packages">packages page</a> for more information on
              Windstream phone and DISH TV services that you can add to your
              internet plan.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much is internet with Windstream in ${stateName}?`,
        content: (
          <div>
            <Typography>
              The price of Windstream Internet depends on which plan you select.
              View the top of the page to see which packages are available in
              your area.
            </Typography>
          </div>
        ),
      },
      {
        title: `How do I get a new Windstream service in ${stateName} at my place of residence?`,
        content: (
          <div>
            <Typography>
              Getting a new Windstream service in {stateName} is easy. Simply
              call <a href="tel:18555452034">1-855-545-2034</a> to order your
              new service, and a customer service representative will walk you
              through the steps.
            </Typography>
            <Typography>
              If you&apos;re moving your Windstream service from one location to
              another, our{' '}
              <a
                href="https://www.getwindstream.com/resources/windstream-moving-guide/"
                target="_blank"
                rel="noreferrer"
              >
                moving guide
              </a>{' '}
              is a great resource to help you in your moving process.
            </Typography>
          </div>
        ),
      },
    ],
  }

  const capitalizeFirstLetter = (city) => {
    return city.replace(/\b\w/g, (char) => char.toUpperCase())
  }

  const cityDropdownData = data.cities.edges.map((current) => ({
    label: capitalizeFirstLetter(current.node.City),
    value: current.node.Page_Path,
  }))

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Hero
            customLink={GatsbyLink}
            backgroundColor="dark"
            textColor="light"
            image={
              <Image
                data={data.heroImage.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="A woman smiles while looking at laptop"
              />
            }
            mobileImage={
              <Image
                data={data.mobileHeroImage.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="A woman smiles while looking at laptop"
              />
            }
            mainContent={
              <>
                <Typography variant="h1">
                  Kinetic by Windstream Internet in {stateName}
                </Typography>
                <Typography variant="body">
                  Experience fast, dependable internet in {stateName}.
                </Typography>
                <List style={{ marginBottom: '16px' }}>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>Unlimited internet</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>No annual contracts</Typography>
                    </Typography>
                  </ListItem>
                </List>
                <AvailabilityForm
                  label="Enter Your Zip Code"
                  buttonText="Check availability"
                  buttonColor="primary"
                />
              </>
            }
          />
          <SeoCtaBar />
          <VariableContent
            backgroundColor="light"
            mainContent={
              <>
                <Typography variant="h2">Windstream Internet Plans</Typography>
                <Typography variant="body">
                  Kinetic by Windstream offers high-speed, reliable internet in{' '}
                  {stateName}. With no annual contracts, no early termination
                  fees, and no required bundling, itâ€™s easier than ever to
                  pick the perfect plan to fit your unique needs.
                </Typography>
              </>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl">
              <Columns style={{ marginTop: '20px' }}>
                {packageList.map((currentPackage) => (
                  <PackageCard
                    label={currentPackage.node.label}
                    packageData={{ ...currentPackage.node.brandy }}
                    key={currentPackage.node.contentful_id}
                    content={
                      <>
                        <LeshenPhoneCTA color="primary">
                          Order Today!{' '}
                        </LeshenPhoneCTA>
                      </>
                    }
                  />
                ))}
              </Columns>
            </Stack>
            <Typography
              style={{
                textAlign: 'center',
                marginTop: '50px',
              }}
              variant="body"
            >
              <ModalWithState modalText="See terms and conditions">
                <Brandy
                  symbol={data.disclaimerPackageCards.symbol}
                  text={data.disclaimerPackageCards.text}
                  variant="legal"
                />
              </ModalWithState>
            </Typography>
          </VariableContent>

          <SplitContent
            backgroundColor="white"
            customLink={GatsbyLink}
            image={
              <Image
                data={data.womanWithLaptop.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="woman sitting on couch working on the computer"
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Is Windstream available near me?
                </Typography>
                <Typography>
                  Check out the cities in {stateName} where Windstream Internet
                  is available.
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />
          <VariableContent
            alignMainContent="center"
            backgroundColor="light"
            mainContent={
              <>
                <Typography variant="h2">
                  Windstream services in {stateName}
                </Typography>
                <Typography variant="body">{State_Specific_Content}</Typography>
              </>
            }
          />
          <Banner
            backgroundColor="secondary"
            mainContent={
              <>
                <Typography variant="h4">Limited Time Offer!</Typography>
                <Typography variant="h4">
                  Receive a $100 Kinetic Reward Prepaid MastercardÂ® with
                  Internet Purchase*
                </Typography>
                <ModalWithState modalText="*See offer conditions">
                  <Brandy text={data.bannerDisclaimer.text} />
                </ModalWithState>
              </>
            }
            image={<Image data={data.visaCard.cloudinary[0].gatsbyImageData} />}
          />
          <VariableContent
            alignMainContent="center"
            backgroundColor="light"
            mainContent={
              <>
                <Typography variant="h2">
                  Benefits of Kinetic Internet in {stateName}
                </Typography>
              </>
            }
          >
            <Stack spacing="xxl">
              <Columns leftAligned={false}>
                <Column
                  image={
                    <SVGImage
                      svg={data.lightningIcon.cloudinary[0].svg.code}
                      presentationWidth={
                        data.lightningIcon.cloudinary[0].svg.presentationWidth
                      }
                    />
                  }
                >
                  <Typography variant="h4">Unlimited Internet</Typography>
                  <Typography>
                    No data caps. Surf and stream worry free all week long.
                  </Typography>
                </Column>
                <Column
                  image={
                    <SVGImage
                      svg={data.infinityIcon.cloudinary[0].svg.code}
                      presentationWidth={
                        data.infinityIcon.cloudinary[0].svg.presentationWidth
                      }
                    />
                  }
                >
                  <Typography variant="h4">No Annual Contracts</Typography>
                  <Typography>
                    No early termination fees or year-long contracts. Cancel
                    anytime.
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
          <VariableContent
            alignMainContent="center"
            backgroundColor="white"
            mainContent={
              <>
                <Typography variant="h2">Additional Resources</Typography>
              </>
            }
          >
            <Stack spacing="xxl">
              <Columns leftAligned={false}>
                <Column>
                  <Typography variant="h5">
                    <a
                      style={{ color: '#22a881' }}
                      href="https://www.getwindstream.com/resources/windstream-moving-guide"
                    >
                      Windstream moving guide
                    </a>
                  </Typography>
                  <Typography>
                    Check out our guide on moving your Windstream internet to
                    take the stress out of changing homes.
                  </Typography>
                </Column>
                <Column>
                  <Typography variant="h5">
                    <a
                      style={{ color: '#22a881' }}
                      href="https://www.getwindstream.com/resources/is-100-mbps-fast"
                    >
                      Is 100 Mbps Internet Fast?
                    </a>
                  </Typography>
                  <Typography>
                    Learn if 100 Mbps internet is fast enough for your online
                    needs.
                  </Typography>
                </Column>
                <Column>
                  <Typography variant="h5">
                    <a
                      style={{ color: '#22a881' }}
                      href="https://www.getwindstream.com/resources/mbps-vs-gbps-internet"
                    >
                      Do I need Mbps or Gbps internet?
                    </a>
                  </Typography>
                  <Typography>
                    Find out the difference between megabits and gigabits to
                    determine which is best for you.
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
          <VariableContent
            backgroundColor="light"
            mainContent={
              <Typography variant="h2">Frequently Asked Questions</Typography>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl" alignMainContent="center">
              <Accordion items={FaqQuestions[FAQ_Group]} />
            </Stack>
          </VariableContent>
        </>
      }
    />
  )
}

StateTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default StateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateTemplateQuery($pagePath: String!, $stateAbbreviation: String!) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerGetwindstreamAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          FAQ_Group
          State
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
          State_Specific_Content
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "6a23be44-8383-5acd-89d2-ed8897bb9490" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "3917969e-1614-5d22-a749-99fee5972b07" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
    cities: allDatasetManagerGetwindstreamAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    heroImage: contentfulMedia(
      contentful_id: { eq: "31JYTjCaD4dQNBy3dcNcWZ" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    mobileHeroImage: contentfulMedia(
      contentful_id: { eq: "4o44YaDbJD2OxVQzDX9a5p" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    womanWithLaptop: contentfulMedia(
      contentful_id: { eq: "7GgrPc2W5o1HwiIz3srTgi" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    gamer: contentfulMedia(contentful_id: { eq: "5kBt7zdZncYtYvLGHsbyG7" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 200)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    dadAndSon: contentfulMedia(
      contentful_id: { eq: "4jxAKsERjG8HQbtzGtBe4J" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 200)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    laptopBed: contentfulMedia(
      contentful_id: { eq: "1UZeGT03BSboC3aJdzphmZ" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 200)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    visaCard: contentfulMedia(contentful_id: { eq: "1AmYFIwjFnhnKBDtFV63dp" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 200)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    videoBuffering: contentfulMedia(
      contentful_id: { eq: "2eqDEtLnNPdiAmbX19mHMX" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 200)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    lightningIcon: contentfulMedia(
      contentful_id: { eq: "6N2R6ezglUhgBLGx771KLF" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    infinityIcon: contentfulMedia(
      contentful_id: { eq: "2eaxIDrqLI58yCD3IIuQ2g" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    moneyIcon: contentfulMedia(
      contentful_id: { eq: "3hLjfW7ggZGRb5vRZQRHBW" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    allContentfulPackage {
      edges {
        node {
          __typename # used to determine component to render
          id
          contentful_id # required to make association
          identifier # user created contentful identifier
          brandy {
            name
            details
            bullets {
              text
              disclaimer {
                text
                symbol
              }
            }
            price {
              currency
              sale
              amount
              duration
              prefix
              suffix
              before
              disclaimer {
                text
                symbol
              }
            }
          }
          label
        }
      }
    }
    bannerDisclaimer: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-visa-prepaid-card" }
    ) {
      symbol
      text
    }
    disclaimerPackageCards: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-wds-internetterms" }
    ) {
      id
      text
      symbol
    }
  }
`
